<script setup lang="ts">
export interface Props {
  mode?: 'both' | 'mark' | 'type'
  direction?: 'horizontal' | 'vertical'
  markBottomFill?: string
  markMiddleFill?: string
  markTopStroke?: string
  strokeMark?: boolean
  animateOnHover?: boolean
}

// define the props and the default
const props = withDefaults(defineProps<Props>(), {
  mode: 'both',
  direction: 'vertical',
  markBottomFill: '#EA3F32',
  markMiddleFill: '#3805F5',
  markTopStroke: '#FFFD5A',
  strokeMark: false,
  animateOnHover: false,
})

const alternateColors = [
  { markBottomFill: '#EA3F32', markMiddleFill: '#3805F5', markTopStroke: '#FFFD5A' }, // Default
  { markBottomFill: '#bc6c25', markMiddleFill: '#606c38', markTopStroke: '#fefae0' },
  { markBottomFill: '#a2d2ff', markMiddleFill: '#ffafcc', markTopStroke: '#bde0fe' },
  { markBottomFill: '#fb8500', markMiddleFill: '#219ebc', markTopStroke: '#ffb703' },
  { markBottomFill: '#e63946', markMiddleFill: '#457b9d', markTopStroke: '#f1faee' },
  { markBottomFill: '#faedcd', markMiddleFill: '#d4a373', markTopStroke: '#ccd5ae' },
  { markBottomFill: '#8338ec', markMiddleFill: '#ff006e', markTopStroke: '#3a86ff' },
  { markBottomFill: '#06d6a0', markMiddleFill: '#ef476f', markTopStroke: '#ffd166' },
]

const hovering = ref(false)
const randomNumber = ref<number | null>(null)
let intervalId: NodeJS.Timeout | null = null

function generateRandomNumber() {
  const min = 0
  const max = alternateColors.length - 1
  randomNumber.value = Math.floor(Math.random() * (max - min + 1)) + min
}

function startGeneratingRandomNumbers() {
  generateRandomNumber() // Generate an initial random number
  intervalId = setInterval(generateRandomNumber, 200)
}

function stopGeneratingRandomNumbers() {
  clearInterval(intervalId as NodeJS.Timeout)
  intervalId = null
}

watch(hovering, (value) => {
  if (value) {
    startGeneratingRandomNumbers()
  }
  else {
    stopGeneratingRandomNumbers()
  }
})

const finalColors = computed(() => {
  if (props.animateOnHover && hovering.value && randomNumber.value !== null) {
    return alternateColors[randomNumber.value]
  }

  return {
    markBottomFill: props.markBottomFill,
    markMiddleFill: props.markMiddleFill,
    markTopStroke: props.markTopStroke,
  }
})
</script>

<template>
  <VoixLogoHorizontal
    v-if="direction === 'horizontal'"
    :mode="mode"
    :stroke-mark="strokeMark"
    :mark-bottom-fill="finalColors.markBottomFill"
    :mark-middle-fill="finalColors.markMiddleFill"
    :mark-top-stroke="finalColors.markTopStroke"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  />
</template>
